import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Stack, Table, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'

interface HolderItem {
  name: string
  alias: string
  capital: Array<{
    amomon: string
    paymet: string
    time: string
    percent: string
  }>
}

const HolderPieChart = ({ holders }: { holders: HolderItem[] }) => {
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}%',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'outside',
          formatter: '{b}\n{c}%',
          fontSize: 12,
        },
        labelLine: {
          show: true,
          length: 10,
          length2: 10,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        data: holders.map((holder) => ({
          name: holder.name,
          value: parseFloat(holder.capital[0]?.percent || '0'),
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const HoldersTable = ({ holders }: { holders: HolderItem[] }) => {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>股东名称</Table.Th>
          <Table.Th>认缴出资</Table.Th>
          <Table.Th>出资比例</Table.Th>
          <Table.Th>认缴时间</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {holders.map((holder) => {
          const capitalInfo = holder.capital[0]
          return (
            <Table.Tr key={holder.name}>
              <Table.Td>{holder.name}</Table.Td>
              <Table.Td>{capitalInfo?.amomon || '-'}</Table.Td>
              <Table.Td>{capitalInfo?.percent || '-'}</Table.Td>
              <Table.Td>{capitalInfo?.time || '-'}</Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
  )
}

export default function TianyanchaHolder({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_holder ?? '{}')
  const holders: HolderItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        {holders.length > 0 ? (
          <>
            <ItemWrapper>
              <LabeledTitle title={'股权结构'} />
              <HolderPieChart holders={holders} />
            </ItemWrapper>
            <ItemWrapper>
              <LabeledTitle title={'股东信息'} />
              <HoldersTable holders={holders} />
            </ItemWrapper>
          </>
        ) : (
          <Text c="dimmed" ta="center" py="md">
            暂无股东信息
          </Text>
        )}
      </Stack>
    </ReportWrapper>
  )
}
