import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Box, Grid, Stack, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import dayjs from 'dayjs'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface TaxContraventionItem {
  publish_time: string
  case_type: string
  department: string
  taxpayer_name: string
}

const CaseTypePieChart = ({ contraventions }: { contraventions: TaxContraventionItem[] }) => {
  // 统计各类型案件数量
  const typeStats = contraventions.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.case_type] = (acc[curr.case_type] || 0) + 1
    return acc
  }, {})

  const data = Object.entries(typeStats).map(([name, value]) => ({ name, value }))

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      bottom: 0,
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: data.map((item) => ({
          ...item,
          itemStyle: { color: '#ff6b6b' },
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const TimeTrendChart = ({ contraventions }: { contraventions: TaxContraventionItem[] }) => {
  // 按月份统计违法记录数量
  const monthStats = contraventions.reduce((acc: { [key: string]: number }, curr) => {
    const month = dayjs(curr.publish_time).format('YYYY-MM')
    acc[month] = (acc[month] || 0) + 1
    return acc
  }, {})

  const months = Object.keys(monthStats).sort()
  const counts = months.map((month) => monthStats[month])

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: '违法记录数',
        type: 'line',
        data: counts,
        itemStyle: { color: '#ff6b6b' },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: 'rgba(255, 107, 107, 0.3)' },
              { offset: 1, color: 'rgba(255, 107, 107, 0)' },
            ],
          },
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const DepartmentBarChart = ({ contraventions }: { contraventions: TaxContraventionItem[] }) => {
  // 统计各执法单位的执法次数
  const departmentStats = contraventions.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.department] = (acc[curr.department] || 0) + 1
    return acc
  }, {})

  const departments = Object.keys(departmentStats)
  const counts = departments.map((dept) => departmentStats[dept])

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: departments,
    },
    series: [
      {
        name: '违法记录数',
        type: 'bar',
        data: counts,
        itemStyle: {
          color: '#ff6b6b',
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const TaxSummary = ({ contraventions }: { contraventions: TaxContraventionItem[] }) => {
  const totalCount = contraventions.length
  const departmentCount = new Set(contraventions.map((item) => item.department)).size
  const latestRecord =
    contraventions.length > 0
      ? contraventions.reduce((latest, curr) =>
          dayjs(curr.publish_time).isAfter(dayjs(latest.publish_time)) ? curr : latest
        )
      : null

  return (
    <Grid grow>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="red">
            {totalCount}
          </Text>
          <Text size="sm" c="dimmed">
            总违法记录
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="red">
            {departmentCount}
          </Text>
          <Text size="sm" c="dimmed">
            涉及执法单位
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700}>
            {latestRecord ? dayjs(latestRecord.publish_time).format('YYYY-MM') : '-'}
          </Text>
          <Text size="sm" c="dimmed">
            最近违法时间
          </Text>
        </Box>
      </Grid.Col>
    </Grid>
  )
}

const TaxContraventionCard = ({ contravention }: { contravention: TaxContraventionItem }) => {
  return (
    <Stack gap="xs">
      <div className="flex items-center gap-2">
        <Text size="sm" fw={500}>
          {contravention.case_type}
        </Text>
      </div>

      <Text size="sm">发布时间：{contravention.publish_time}</Text>
      <Text size="sm">执法单位：{contravention.department}</Text>
      <Text size="sm">纳税人名称：{contravention.taxpayer_name}</Text>
    </Stack>
  )
}

export default function TianyanchaTaxContravention({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_taxContravention ?? '{}')
  const contraventions: TaxContraventionItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <TaxSummary contraventions={contraventions} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'案件类型分布'} />
          <CaseTypePieChart contraventions={contraventions} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'违法记录趋势'} />
          <TimeTrendChart contraventions={contraventions} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'执法单位分布'} />
          <DepartmentBarChart contraventions={contraventions} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'违法详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={contraventions.length === 0}>
              {contraventions.map((contravention, index) => (
                <Box
                  key={index}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <TaxContraventionCard contravention={contravention} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
