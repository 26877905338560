import { Box, Flex, LoadingOverlay, Stack, Text, Title } from '@mantine/core'
import { getModuleNodes } from './ModuleUtil'
import ReportFooter from './ReportFooter'
import React, { useState } from 'react'
import RightFloating from '../../components/RightFloating'
import FloatingButton from '../../components/FloatingButton'
import { useNavigate } from 'react-router-dom'
import Share from '../../components/Share'
import Stamp from './Stamp'
import { getStampStr, isNormal } from './ReportUtils'

interface MainProps {
  data: any
  isLoading: boolean
}

export default function Main({ data, isLoading }: MainProps) {
  const navigate = useNavigate()
  const [shareVisible, setShareVisible] = useState<boolean>(false)

  return (
    <div className={'max-w-xl m-auto bg-[#E6EFFF]'} id={'report'}>
      {isLoading && <LoadingOverlay zIndex={1000} visible overlayProps={{ radius: 'sm', blur: 2 }} />}
      <Stack>
        <div className={'relative'}>
          <Flex
            direction="column"
            style={{
              width: '100%',
              background: 'linear-gradient(135deg, #174AFE 0%, #4B75FF 100%)',
              padding: '28px 24px',
              height: '240px',
              position: 'relative',
            }}
          >
            <Text c="white" size="xs" fw={400} style={{ letterSpacing: '0.5px', opacity: 0.9 }}>
              {data?.meta_info_code}
            </Text>
            <Stack gap={20} c={'white'} style={{ marginTop: '24px' }}>
              <Title order={2} style={{ fontSize: '28px', fontWeight: 600, letterSpacing: '-0.5px' }}>
                {data?.meta_info_title || '个人大数据风险报告'}
              </Title>
              <Box
                style={{
                  background: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(10px)',
                  padding: '16px',
                  borderRadius: '8px',
                }}
              >
                <Text size="xs" style={{ lineHeight: 1.5 }}>
                  免责声明：本报告仅作为大数据信息汇总，不作为任何决策或判断的依据。我们不提供任何贷款、金融等相关业务，请勿轻信他人利用本报告进行欺诈，如有疑问请联系客服。
                </Text>
              </Box>
            </Stack>
          </Flex>
          <Stamp text={getStampStr(data)} isNormal={isNormal(data)} />
        </div>
        <div className={'mt-[-60px]'}>{getModuleNodes(data)}</div>
        <ReportFooter />
      </Stack>
      <RightFloating>
        <Stack align={'flex-end'}>
          <FloatingButton
            key={'share'}
            onClick={() => {
              setShareVisible(true)
            }}
            image={'../share.png'}
          />
          <FloatingButton
            key={'history'}
            onClick={() => {
              navigate('/orders')
            }}
            image={'../history.png'}
          />
        </Stack>
      </RightFloating>
      <Share shareVisible={shareVisible} setShareVisible={setShareVisible} />
    </div>
  )
}
