import http, { ResponseData } from './httpService'

export const searchCompany = async (keyword: string): Promise<string> => {
  const { data } = await http.get<ResponseData<string>>('/fe/common/searchCompany', {
    params: {
      keyword,
    },
  })
  return data.data
}
