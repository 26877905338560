import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import ItemWrapper from '../../../components/ItemWrapper'
import RelationCompany from './RelationCompany'
import PageEmpty from '../../../components/PageEmpty'

export default function TianyuanRelationCompanyInfo({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyuan_relation_company_info ?? '{}')
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        {(jsonData?.datalist ?? []).length === 0 && <PageEmpty image={'/good.png'} title={'暂无数据'} size={68} />}
        {(jsonData?.datalist ?? []).map((item: any, index: number) => {
          return <RelationCompany number={index + 1} key={index} item={item} />
        })}
      </ItemWrapper>
    </ReportWrapper>
  )
}
