import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

export default function UdatatecMarriageInfo({ data, title }: { data: any; title: string }) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <ReportItem
          name={'婚姻状态'}
          value={data?.udatatec_marriage_info == '未查得' ? '未婚' : data?.udatatec_marriage_info}
        />
      </ItemWrapper>
    </ReportWrapper>
  )
}
