import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import ReactECharts from 'echarts-for-react'
import ItemWrapper from '../../../components/ItemWrapper'

interface BankApplicationRecordsOverviewProps {
  data: any
}

export default function CompanyBankApplicationRecordsOverview({ data }: BankApplicationRecordsOverviewProps) {
  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: ['申请次数', '申请机构数'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: ['近7天', '近15天', '近1月', '近3月', '近6月', '近1年'],
      axisLabel: {
        fontSize: 10,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      axisLabel: {
        formatter: '{value}',
      },
    },
    series: [
      {
        name: '申请次数',
        type: 'bar',
        label: {
          show: true,
        },
        data: [
          data?.b_d7_apply_count ?? 0,
          data?.b_d15_apply_count ?? 0,
          data?.b_m1_apply_count ?? 0,
          data?.b_m3_apply_count ?? 0,
          data?.b_m6_apply_count ?? 0,
          data?.b_m12_apply_count ?? 0,
        ],
      },
      {
        name: '申请机构数',
        type: 'bar',
        label: {
          show: true,
        },
        data: [
          data?.b_d7_apply_org_count ?? 0,
          data?.b_d15_apply_org_count ?? 0,
          data?.b_m1_apply_org_count ?? 0,
          data?.b_m3_apply_org_count ?? 0,
          data?.b_m6_apply_org_count ?? 0,
          data?.b_m12_apply_org_count ?? 0,
        ],
      },
    ],
  }

  return (
    <ItemWrapper>
      <LabeledTitle title={'申请汇总'} />
      <ReactECharts option={options} />
    </ItemWrapper>
  )
}
