import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Badge, Box, Grid, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface IllegalItem {
  putReason: string
  putDepartment: string
  putDate: number
  removeDepartment: string
  removeReason: string
}

const IllegalStatusPieChart = ({ illegals }: { illegals: IllegalItem[] }) => {
  const removed = illegals.filter((item) => item.removeDepartment && item.removeReason).length
  const notRemoved = illegals.length - removed

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      bottom: 0,
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: removed, name: '已移出', itemStyle: { color: '#4caf50' } },
          { value: notRemoved, name: '未移出', itemStyle: { color: '#ff6b6b' } },
        ],
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const IllegalTrendChart = ({ illegals }: { illegals: IllegalItem[] }) => {
  // 按月份统计列入和移出的数量
  const monthStats = illegals.reduce((acc: { [key: string]: { put: number; remove: number } }, curr) => {
    const putMonth = dayjs(curr.putDate).format('YYYY-MM')
    if (!acc[putMonth]) {
      acc[putMonth] = { put: 0, remove: 0 }
    }
    acc[putMonth].put++

    if (curr.removeDepartment && curr.removeReason) {
      // 假设移出时间与列入时间相同，因为数据中似乎没有移出时间
      const removeMonth = putMonth
      if (!acc[removeMonth]) {
        acc[removeMonth] = { put: 0, remove: 0 }
      }
      acc[removeMonth].remove++
    }

    return acc
  }, {})

  const months = Object.keys(monthStats).sort()
  const putData = months.map((month) => monthStats[month].put)
  const removeData = months.map((month) => monthStats[month].remove)

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['列入', '移出'],
      bottom: 0,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: '列入',
        type: 'line',
        data: putData,
        itemStyle: { color: '#ff6b6b' },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: 'rgba(255, 107, 107, 0.3)' },
              { offset: 1, color: 'rgba(255, 107, 107, 0)' },
            ],
          },
        },
      },
      {
        name: '移出',
        type: 'line',
        data: removeData,
        itemStyle: { color: '#4caf50' },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: 'rgba(76, 175, 80, 0.3)' },
              { offset: 1, color: 'rgba(76, 175, 80, 0)' },
            ],
          },
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const IllegalDepartmentChart = ({ illegals }: { illegals: IllegalItem[] }) => {
  // 统计各部门的违法记录数
  const departmentStats = illegals.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.putDepartment] = (acc[curr.putDepartment] || 0) + 1
    return acc
  }, {})

  const departments = Object.keys(departmentStats)
  const counts = departments.map((dept) => departmentStats[dept])

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: departments,
    },
    series: [
      {
        name: '违法记录数',
        type: 'bar',
        data: counts,
        itemStyle: {
          color: '#ff6b6b',
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const IllegalSummary = ({ illegals }: { illegals: IllegalItem[] }) => {
  const totalCount = illegals.length
  const removedCount = illegals.filter((item) => item.removeDepartment && item.removeReason).length
  const latestRecord =
    illegals.length > 0
      ? illegals.reduce((latest, curr) => (dayjs(curr.putDate).isAfter(dayjs(latest.putDate)) ? curr : latest))
      : null

  return (
    <Grid grow>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="red">
            {totalCount}
          </Text>
          <Text size="sm" c="dimmed">
            总违法记录
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="red">
            {removedCount}
          </Text>
          <Text size="sm" c="dimmed">
            已移出记录
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700}>
            {latestRecord ? dayjs(latestRecord.putDate).format('YYYY-MM') : '-'}
          </Text>
          <Text size="sm" c="dimmed">
            最近违法时间
          </Text>
        </Box>
      </Grid.Col>
    </Grid>
  )
}

const IllegalCard = ({ illegal }: { illegal: IllegalItem }) => {
  const isRemoved = illegal.removeDepartment && illegal.removeReason

  return (
    <Stack gap="xs">
      <div className="flex items-center gap-2">
        <Badge color={isRemoved ? 'green' : 'red'}>{isRemoved ? '已移出' : '未移出'}</Badge>
        <Text size="sm" fw={500}>
          {illegal.putReason}
        </Text>
      </div>

      <Text size="sm">列入日期：{dayjs(illegal.putDate).format('YYYY-MM-DD')}</Text>
      <Text size="sm">列入机关：{illegal.putDepartment}</Text>

      {isRemoved && (
        <>
          <Text size="sm">移出机关：{illegal.removeDepartment}</Text>
          <Text size="sm">移出原因：{illegal.removeReason}</Text>
        </>
      )}
    </Stack>
  )
}

export default function TianyanchaIllegalinfo({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_illegalinfo ?? '{}')
  const illegals: IllegalItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <IllegalSummary illegals={illegals} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'违法记录状态'} />
          <IllegalStatusPieChart illegals={illegals} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'违法记录趋势'} />
          <IllegalTrendChart illegals={illegals} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'执法部门分布'} />
          <IllegalDepartmentChart illegals={illegals} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'违法详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={illegals.length === 0}>
              {illegals.map((illegal, index) => (
                <Box
                  key={index}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <IllegalCard illegal={illegal} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
