import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Badge, Box, Grid, Image, Stack, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface AppbkItem {
  brief: string
  classes: string
  filterName: string
  icon: string
  name: string
  type: string
  uuid: string
}

const ProductTypePieChart = ({ appbks }: { appbks: AppbkItem[] }) => {
  // 统计各类型产品数量
  const typeStats = appbks.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.type] = (acc[curr.type] || 0) + 1
    return acc
  }, {})

  const data = Object.entries(typeStats).map(([name, value]) => ({ name, value }))

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      bottom: 0,
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: data.map((item) => ({
          ...item,
          itemStyle: { color: '#228be6' },
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const ProductSummary = ({ appbks }: { appbks: AppbkItem[] }) => {
  const totalCount = appbks.length
  const typeCount = new Set(appbks.map((item) => item.type)).size
  const classCount = new Set(appbks.flatMap((item) => item.classes.split('/'))).size

  return (
    <Grid grow>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="blue">
            {totalCount}
          </Text>
          <Text size="sm" c="dimmed">
            总产品数量
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="blue">
            {typeCount}
          </Text>
          <Text size="sm" c="dimmed">
            产品类型数
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="blue">
            {classCount}
          </Text>
          <Text size="sm" c="dimmed">
            产品分类数
          </Text>
        </Box>
      </Grid.Col>
    </Grid>
  )
}

const AppbkCard = ({ appbk }: { appbk: AppbkItem }) => {
  return (
    <div className="flex gap-4">
      <div style={{ width: '32px', height: '32px', flexShrink: 0 }}>
        <Image
          src={appbk.icon}
          alt={appbk.name}
          width={32}
          height={32}
          radius="sm"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      </div>
      <Stack gap="xs" style={{ flex: 1 }}>
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500}>
            {appbk.name}
          </Text>
          <Badge variant="light" color="blue">
            {appbk.type}
          </Badge>
          <Badge variant="light" color="gray">
            {appbk.classes}
          </Badge>
        </div>

        <Text size="sm" c="dimmed" style={{ whiteSpace: 'pre-wrap' }}>
          {appbk.brief}
        </Text>
      </Stack>
    </div>
  )
}

export default function TianyanchaAppbkInfo({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_appbkInfo ?? '{}')
  const appbks: AppbkItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <ProductSummary appbks={appbks} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'产品类型分布'} />
          <ProductTypePieChart appbks={appbks} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'产品详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={appbks.length === 0}>
              {appbks.map((appbk) => (
                <Box
                  key={appbk.uuid}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <AppbkCard appbk={appbk} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
