import { Group, Table, TableData, Tabs } from '@mantine/core'
import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import ItemWrapper from '../../../components/ItemWrapper'
import { IconListDetails, IconTable } from '@tabler/icons-react'
import useViewMode from '../hooks/useViewMode'

interface InstitutionalApplicationRecordsProps {
  data: any
}

export default function CompanyBankInstitutionalApplicationRecords({ data }: InstitutionalApplicationRecordsProps) {
  const [viewMode, setViewMode] = useViewMode(data)

  const lastSevenTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['全国性银行', data?.b_d7_apply_tra_count ?? 0, data?.b_d7_apply_tra_org_count ?? 0],
      ['区域银行', data?.b_d7_apply_reg_count ?? 0, data?.b_d7_apply_reg_org_count ?? 0],
      ['网络零售银行', data?.b_d7_apply_ret_count ?? 0, data?.b_d7_apply_ret_org_count ?? 0],
    ],
  }

  const lastFifteenTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['全国性银行', data?.b_d15_apply_tra_count ?? 0, data?.b_d15_apply_tra_org_count ?? 0],
      ['区域银行', data?.b_d15_apply_reg_count ?? 0, data?.b_d15_apply_reg_org_count ?? 0],
      ['网络零售银行', data?.b_d15_apply_ret_count ?? 0, data?.b_d15_apply_ret_org_count ?? 0],
    ],
  }

  const lastMonthTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['全国性银行', data?.b_m1_apply_tra_count ?? 0, data?.b_m1_apply_tra_org_count ?? 0],
      ['区域银行', data?.b_m1_apply_reg_count ?? 0, data?.b_m1_apply_reg_org_count ?? 0],
      ['网络零售银行', data?.b_m1_apply_ret_count ?? 0, data?.b_m1_apply_ret_org_count ?? 0],
    ],
  }

  const lastThreeMonthsTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['全国性银行', data?.b_m3_apply_tra_count ?? 0, data?.b_m3_apply_tra_org_count ?? 0],
      ['区域银行', data?.b_m3_apply_reg_count ?? 0, data?.b_m3_apply_reg_org_count ?? 0],
      ['网络零售银行', data?.b_m3_apply_ret_count ?? 0, data?.b_m3_apply_ret_org_count ?? 0],
    ],
  }

  const lastSixMonthsTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['全国性银行', data?.b_m6_apply_tra_count ?? 0, data?.b_m6_apply_tra_org_count ?? 0],
      ['区域银行', data?.b_m6_apply_reg_count ?? 0, data?.b_m6_apply_reg_org_count ?? 0],
      ['网络零售银行', data?.b_m6_apply_ret_count ?? 0, data?.b_m6_apply_ret_org_count ?? 0],
    ],
  }

  const lastYearTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['全国性银行', data?.b_m12_apply_tra_count ?? 0, data?.b_m12_apply_tra_org_count ?? 0],
      ['区域银行', data?.b_m12_apply_reg_count ?? 0, data?.b_m12_apply_reg_org_count ?? 0],
      ['网络零售银行', data?.b_m12_apply_ret_count ?? 0, data?.b_m12_apply_ret_org_count ?? 0],
    ],
  }

  const toggleViewMode = () => {
    setViewMode(viewMode === 'table' ? 'tile' : 'table')
  }

  function getTables() {
    return (
      <Tabs
        defaultValue="lastSeven"
        styles={{
          tab: {
            paddingRight: 8,
            paddingLeft: 8,
          },
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="lastSeven">近7天</Tabs.Tab>
          <Tabs.Tab value="lastFifteen">近15天</Tabs.Tab>
          <Tabs.Tab value="lastMonth">近1月</Tabs.Tab>
          <Tabs.Tab value="lastThreeMonths">近3月</Tabs.Tab>
          <Tabs.Tab value="lastSixMonths">近6月</Tabs.Tab>
          <Tabs.Tab value="lastYear">近1年</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="lastSeven">
          <Table data={lastSevenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastFifteen">
          <Table data={lastFifteenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastMonth">
          <Table data={lastMonthTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastThreeMonths">
          <Table data={lastThreeMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastSixMonths">
          <Table data={lastSixMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastYear">
          <Table data={lastYearTableData} />
        </Tabs.Panel>
      </Tabs>
    )
  }

  const getTiles = () => {
    return (
      <Group>
        <Group>
          <LabeledTitle title={'近7天'} showImage={false} />
          <Table data={lastSevenTableData} />
        </Group>
        <Group>
          <LabeledTitle title={'近15天'} showImage={false} />
          <Table data={lastFifteenTableData} />
        </Group>
        <Group>
          <LabeledTitle title={'近1月'} showImage={false} />
          <Table data={lastMonthTableData} />
        </Group>
        <Group>
          <LabeledTitle title={'近3月'} showImage={false} />
          <Table data={lastThreeMonthsTableData} />
        </Group>
        <Group>
          <LabeledTitle title={'近6月'} showImage={false} />
          <Table data={lastSixMonthsTableData} />
        </Group>
        <Group>
          <LabeledTitle title={'近1年'} showImage={false} />
          <Table data={lastYearTableData} />
        </Group>
      </Group>
    )
  }

  return (
    <ItemWrapper>
      <Group justify={'space-between'}>
        <LabeledTitle title={'机构类别申请记录'} />
        {viewMode === 'table' ? (
          <IconListDetails onClick={toggleViewMode} color={'#174AFE'} />
        ) : (
          <IconTable onClick={toggleViewMode} color={'#174AFE'} />
        )}
      </Group>
      {viewMode === 'table' ? getTables() : getTiles()}
    </ItemWrapper>
  )
}
