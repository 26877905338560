import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Box, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface DishonestItem {
  iname: string
  areaname: string
  courtname: string
  casecode: string
  disrupttypename: string
  duty: string
  performance: string
  regdate: number
  publishdate: number
  gistid: string
  gistunit: string
}

const DishonestTypePieChart = ({ dishonests }: { dishonests: DishonestItem[] }) => {
  // 统计失信类型
  const typeStats = dishonests.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.disrupttypename] = (acc[curr.disrupttypename] || 0) + 1
    return acc
  }, {})

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}件',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'outside',
          formatter: '{b}\n{c}件',
          fontSize: 12,
        },
        labelLine: {
          show: true,
          length: 10,
          length2: 10,
        },
        data: Object.entries(typeStats).map(([name, value]) => ({
          name,
          value,
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const DishonestTrendChart = ({ dishonests }: { dishonests: DishonestItem[] }) => {
  // 按月份统计失信记录数量
  const monthStats = dishonests.reduce((acc: { [key: string]: number }, curr) => {
    const month = dayjs(curr.publishdate).format('YYYY-MM')
    acc[month] = (acc[month] || 0) + 1
    return acc
  }, {})

  // 获取排序后的月份
  const months = Object.keys(monthStats).sort()

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: '{b}: {c}件',
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        data: months.map((month) => monthStats[month]),
        type: 'line',
        smooth: true,
        symbolSize: 8,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.2,
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const DishonestCard = ({ dishonest }: { dishonest: DishonestItem }) => {
  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500}>
            {dishonest.disrupttypename}
          </Text>
        </div>

        <Text size="sm">案号：{dishonest.casecode}</Text>
        <Text size="sm">
          执行法院：{dishonest.areaname} {dishonest.courtname}
        </Text>
        <Text size="sm">立案时间：{dayjs(dishonest.regdate).format('YYYY-MM-DD')}</Text>
        <Text size="sm">发布时间：{dayjs(dishonest.publishdate).format('YYYY-MM-DD')}</Text>
        <Text size="sm">履行情况：{dishonest.performance}</Text>

        <div
          className="p-2"
          style={{
            backgroundColor: '#f8f9fa',
            borderRadius: '4px',
          }}
        >
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {dishonest.duty.trim()}
          </Text>
        </div>
      </Stack>
    </Box>
  )
}

export default function TianyanchaDishonest({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_dishonest ?? '{}')
  const dishonests: DishonestItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <LabeledTitle title={'失信类型分布'} />
          <DishonestTypePieChart dishonests={dishonests} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'失信趋势'} />
          <DishonestTrendChart dishonests={dishonests} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'失信详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={dishonests.length === 0}>
              {dishonests.map((dishonest) => (
                <Box
                  key={dishonest.casecode}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <DishonestCard dishonest={dishonest} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
