import ReportOverview from './ReportOverview'
import React, { ReactNode } from 'react'
import BasicDetail from './BasicDetail'
import NonBankingApplicationRecords from './NonBankingApplicationRecords'
import BankApplicationRecords from './BankApplicationRecords'
import ExceptionList from './ExceptionList'
import CourtCases from './CourtCases'
import FailureComplyCases from './FailureComplyCases'
import EnforcementCases from './EnforcementCases'
import LimitHighConsumptionCases from './LimitHighConsumptionCases'
import RelatedPartyAnomalies from './RelatedPartyAnomalies'
import SocialTag from './SocialTag'
import LoanRecords from './LoanRecords'
import BadInformationDetection from './BadInformationDetection'
import MarriageInformation from './MarriageInformation'
import BadInformationDetailDetection from './BadInformationDetailDetection'
import ZjcBasicDetail from './ZjcBasicDetail'
import ZjcBankApplicationRecords from './ZjcBankApplicationRecords'
import ZjcNonBankApplicationRecords from './ZjcNonBankApplicationRecords'
import ZjcBadInformationDetection from './ZjcBadInformationDetection'
import ZjcCourtCases from './ZjcCourtCases'
import ZjcFailureComplyCases from './ZjcFailureComplyCases'
import ZjcEnforcementCases from './ZjcEnforcementCases'
import ZjcLimitHighConsumptionCases from './ZjcLimitHighConsumptionCases'
import TianyuanMarriageInfo from './TianyuanMarriageInfo'
import TianyuanRelationCompanyInfo from './TianyuanRelationCompanyInfo'
import UdatatecMarriageInfo from './UdatatecMarriageInfo'
import TianyanchaBasicInfo from './TianyanchaBasicInfo'
import TianyanchaHolder from './TianyanchaHolder'
import TianyanchaLawSuit from './TianyanchaLawSuit'
import TianyanchaCourtAnnouncement from './TianyanchaCourtAnnouncement'
import TianyanchaDishonest from './TianyanchaDishonest'
import TianyanchaZhixinginfo from './TianyanchaZhixinginfo'
import TianyanchaAbnormal from './TianyanchaAbnormal'
import TianyanchaPunishmentInfo from './TianyanchaPunishmentInfo'
import TianyanchaIllegalinfo from './TianyanchaIllegalinfo'
import TianyanchaTaxContravention from './TianyanchaTaxContravention'
import TianyanchaAppbkInfo from './TianyanchaAppbkInfo'
import TianyanchaBids from './TianyanchaBids'
import TianyanchaCertificate from './TianyanchaCertificate'
import CompanyBankApplicationRecords from './CompanyBankApplicationRecords'
import CompanyNonBankingApplicationRecords from './CompanyNonBankingApplicationRecords'

export const getModuleName = (data: any, key: string) => {
  for (const item of data?.meta_data_module_list ?? []) {
    if (item.hasOwnProperty(key)) {
      return item[key]
    }
  }
}

export const getModuleNodes = (data: any): ReactNode[] => {
  return (data?.meta_data_module_list ?? []).map((item: any) => {
    const key = Object.keys(item)[0]
    switch (key) {
      case '001':
        return <ReportOverview data={data} title={getModuleName(data, '001')} />
      case '002':
        return <BasicDetail data={data} title={getModuleName(data, '002')} />
      case '003':
        return <BankApplicationRecords data={data} title={getModuleName(data, '003')} />
      case '004':
        return <NonBankingApplicationRecords data={data} title={getModuleName(data, '004')} />
      case '010':
        return <ExceptionList data={data} title={getModuleName(data, '010')} />
      case '018':
        return <CourtCases data={data} title={getModuleName(data, '018')} />
      case '017':
        return <FailureComplyCases data={data} title={getModuleName(data, '017')} />
      case '016':
        return <EnforcementCases data={data} title={getModuleName(data, '016')} />
      case '015':
        return <LimitHighConsumptionCases data={data} title={getModuleName(data, '015')} />
      case '011':
        return <RelatedPartyAnomalies data={data} title={getModuleName(data, '011')} />
      case '008':
        return <SocialTag data={data} title={getModuleName(data, '008')} />
      case '014':
        return <LoanRecords data={data} title={getModuleName(data, '014')} />
      case '009':
        return <BadInformationDetection data={data} title={getModuleName(data, '009')} />
      case '012':
        return <MarriageInformation data={data} title={getModuleName(data, '012')} />
      case '013':
        return <BadInformationDetailDetection data={data} title={getModuleName(data, '013')} />
      case 'zjc002':
        return <ZjcBasicDetail data={data} title={getModuleName(data, 'zjc002')} />
      case 'zjc003':
        return <ZjcBankApplicationRecords data={data} title={getModuleName(data, 'zjc003')} />
      case 'zjc004':
        return <ZjcNonBankApplicationRecords data={data} title={getModuleName(data, 'zjc004')} />
      case 'zjc009':
        return <ZjcBadInformationDetection data={data} title={getModuleName(data, 'zjc009')} />
      case 'zjc018':
        return <ZjcCourtCases data={data} title={getModuleName(data, 'zjc018')} />
      case 'zjc017':
        return <ZjcFailureComplyCases data={data} title={getModuleName(data, 'zjc017')} />
      case 'zjc016':
        return <ZjcEnforcementCases data={data} title={getModuleName(data, 'zjc016')} />
      case 'zjc015':
        return <ZjcLimitHighConsumptionCases data={data} title={getModuleName(data, 'zjc015')} />
      case 'tianyuan001':
        return <TianyuanMarriageInfo data={data} title={getModuleName(data, 'tianyuan001')} />
      case 'tianyuan002':
        return <TianyuanRelationCompanyInfo data={data} title={getModuleName(data, 'tianyuan002')} />
      case '019':
        return <BankApplicationRecords data={data} title={getModuleName(data, '019')} />
      case '020':
        return <NonBankingApplicationRecords data={data} title={getModuleName(data, '020')} />
      case '021':
        return <CompanyBankApplicationRecords data={data} title={getModuleName(data, '021')} />
      case '022':
        return <CompanyNonBankingApplicationRecords data={data} title={getModuleName(data, '022')} />
      case 'udatatec001':
        return <UdatatecMarriageInfo data={data} title={getModuleName(data, 'udatatec001')} />
      case 'tianyancha001':
        return <TianyanchaBasicInfo data={data} title={getModuleName(data, 'tianyancha001')} />
      case 'tianyancha002':
        return <TianyanchaHolder data={data} title={getModuleName(data, 'tianyancha002')} />
      case 'tianyancha003':
        return <TianyanchaLawSuit data={data} title={getModuleName(data, 'tianyancha003')} />
      case 'tianyancha004':
        return <TianyanchaCourtAnnouncement data={data} title={getModuleName(data, 'tianyancha004')} />
      case 'tianyancha005':
        return <TianyanchaDishonest data={data} title={getModuleName(data, 'tianyancha005')} />
      case 'tianyancha006':
        return <TianyanchaZhixinginfo data={data} title={getModuleName(data, 'tianyancha006')} />
      case 'tianyancha007':
        return <TianyanchaAbnormal data={data} title={getModuleName(data, 'tianyancha007')} />
      case 'tianyancha008':
        return <TianyanchaPunishmentInfo data={data} title={getModuleName(data, 'tianyancha008')} />
      case 'tianyancha009':
        return <TianyanchaIllegalinfo data={data} title={getModuleName(data, 'tianyancha009')} />
      case 'tianyancha010':
        return <TianyanchaTaxContravention data={data} title={getModuleName(data, 'tianyancha010')} />
      case 'tianyancha011':
        return <TianyanchaAppbkInfo data={data} title={getModuleName(data, 'tianyancha011')} />
      case 'tianyancha012':
        return <TianyanchaBids data={data} title={getModuleName(data, 'tianyancha012')} />
      case 'tianyancha013':
        return <TianyanchaCertificate data={data} title={getModuleName(data, 'tianyancha013')} />
      default:
        return null
    }
  })
}
