import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Badge, Box, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface CompanyItem {
  name: string
  id: string
  type: number
}

interface AnnouncementItem {
  announce_id: number
  party1: string
  party2: string
  reason: string
  bltntypename: string
  content: string
  courtcode: string
  province: string
  publishdate: string
  companyList: CompanyItem[]
}

const AnnouncementTypePieChart = ({ announcements }: { announcements: AnnouncementItem[] }) => {
  // 统计公告类型
  const typeStats = announcements.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.bltntypename] = (acc[curr.bltntypename] || 0) + 1
    return acc
  }, {})

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}件',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'outside',
          formatter: '{b}\n{c}件',
          fontSize: 12,
        },
        labelLine: {
          show: true,
          length: 10,
          length2: 10,
        },
        data: Object.entries(typeStats).map(([name, value]) => ({
          name,
          value,
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const AnnouncementTrendChart = ({ announcements }: { announcements: AnnouncementItem[] }) => {
  // 按月份统计公告数量
  const monthStats = announcements.reduce((acc: { [key: string]: number }, curr) => {
    const month = dayjs(curr.publishdate).format('YYYY-MM')
    acc[month] = (acc[month] || 0) + 1
    return acc
  }, {})

  // 获取排序后的月份
  const months = Object.keys(monthStats).sort()

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: '{b}: {c}件',
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        data: months.map((month) => monthStats[month]),
        type: 'line',
        smooth: true,
        symbolSize: 8,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.2,
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const AnnouncementCard = ({ announcement }: { announcement: AnnouncementItem }) => {
  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500}>
            {announcement.bltntypename}
          </Text>
        </div>

        <div className="flex items-center gap-2 flex-wrap">
          <Badge variant="light">{announcement.reason}</Badge>
        </div>

        <Text size="sm">
          法院：{announcement.province} {announcement.courtcode}
        </Text>
        <Text size="sm">公告日期：{dayjs(announcement.publishdate).format('YYYY-MM-DD')}</Text>

        <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
          公告内容：{announcement.content}
        </Text>

        <Box>
          <div className="grid grid-cols-1 gap-2">
            <div
              className="flex gap-2 p-2"
              style={{
                backgroundColor: '#f8f9fa',
                borderRadius: '4px',
              }}
            >
              <Badge size="sm" variant="light" style={{ flexShrink: 0 }}>
                原告
              </Badge>
              <Text size="sm" style={{ wordBreak: 'break-all' }}>
                {announcement.party1}
              </Text>
            </div>
            <div
              className="flex gap-2 p-2"
              style={{
                backgroundColor: '#f8f9fa',
                borderRadius: '4px',
              }}
            >
              <Badge size="sm" variant="light" style={{ flexShrink: 0 }}>
                被告
              </Badge>
              <Text size="sm" style={{ wordBreak: 'break-all' }}>
                {announcement.party2}
              </Text>
            </div>
          </div>
        </Box>
      </Stack>
    </Box>
  )
}

export default function TianyanchaCourtAnnouncement({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_courtAnnouncement ?? '{}')
  const announcements: AnnouncementItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <LabeledTitle title={'公告类型分布'} />
          <AnnouncementTypePieChart announcements={announcements} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'公告趋势'} />
          <AnnouncementTrendChart announcements={announcements} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'公告详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={announcements.length === 0}>
              {announcements.map((announcement) => (
                <Box
                  key={announcement.announce_id}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <AnnouncementCard announcement={announcement} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
