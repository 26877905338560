import { LoadingOverlay, Stack } from '@mantine/core'
import Order from './components/Order'
import React, { useEffect, useState } from 'react'
import { getOrders } from '../api/orderService'
import { OrderInterface } from '../typings/orderTypes'
import { CapsuleTabs } from 'antd-mobile'
import PageEmpty from '../components/PageEmpty'
import { getEnumByName } from '../api/dictionaryService'

export default function Orders() {
  const [orders, setOrders] = useState<OrderInterface[]>()
  const [filterOrders, setFilterOrders] = useState<OrderInterface[]>()
  const [statusMap, setStatusMap] = useState<Map<string, string>>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const data = await getOrders()
        setOrders(data)
        setFilterOrders(data)
      } catch (error) {
        console.error('Failed to fetch orders:', error)
      } finally {
        setLoading(false)
      }
    }

    const fetchStatusMap = async () => {
      try {
        const data = await getEnumByName('com.yuexiangcha.common.enumeration.IsaacOrderStatusEnum')
        setStatusMap(data)
      } catch (error) {
        console.error('Failed to fetch status map:', error)
      }
    }

    Promise.all([fetchData(), fetchStatusMap()])
  }, [])

  return (
    <div className={'max-w-xl m-auto'}>
      <LoadingOverlay zIndex={1000} visible={loading} overlayProps={{ radius: 'sm', blur: 2 }} />
      <CapsuleTabs
        onChange={(value) => {
          setFilterOrders(
            orders?.filter((order) => {
              if (value === 'all') {
                return true
              }
              return order.status.toString() === value
            })
          )
        }}
      >
        <CapsuleTabs.Tab title="全部" key="all" />
        <CapsuleTabs.Tab title="待支付" key="20" />
        <CapsuleTabs.Tab title="生成中" key="30" />
        <CapsuleTabs.Tab title="已退款" key="100" />
        <CapsuleTabs.Tab title="已取消" key="50" />
        <CapsuleTabs.Tab title="已完成" key="40" />
      </CapsuleTabs>
      <Stack p={'sm'}>
        {!loading && filterOrders?.map((order) => <Order order={order} key={order.id} statusMap={statusMap!} />)}
      </Stack>
      {!loading && filterOrders?.length === 0 && <PageEmpty title={'暂无对应状态的订单'} image={'./empty.png'} />}
    </div>
  )
}
