import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import ReportItem from '../ReportItem'
import ItemWrapper from '../../../components/ItemWrapper'

export default function TianyuanMarriageInfo({ data, title }: { data: any; title: string }) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <ReportItem
          name={'婚姻情况'}
          value={data?.tianyuan_marriage_info === '匹配不成功' ? '未婚' : data?.tianyuan_marriage_info}
        />
      </ItemWrapper>
    </ReportWrapper>
  )
}
