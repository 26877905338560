import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Badge, Box, Stack, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import dayjs from 'dayjs'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface CasePerson {
  result: string
  role: string
  name: string
  sptname: string
}

interface LawSuitItem {
  title: string
  court: string
  judgeTime: string
  caseNo: string
  caseType: string
  caseReason: string
  casePersons: CasePerson[]
  docType: string
}

const CaseTypePieChart = ({ lawsuits }: { lawsuits: LawSuitItem[] }) => {
  // 统计案件类型
  const typeStats = lawsuits.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.caseType] = (acc[curr.caseType] || 0) + 1
    return acc
  }, {})

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}件',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'outside',
          formatter: '{b}\n{c}件',
          fontSize: 12,
        },
        labelLine: {
          show: true,
          length: 10,
          length2: 10,
        },
        data: Object.entries(typeStats).map(([name, value]) => ({
          name,
          value,
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const CaseTrendChart = ({ lawsuits }: { lawsuits: LawSuitItem[] }) => {
  // 按月份统计案件数量
  const monthStats = lawsuits.reduce((acc: { [key: string]: number }, curr) => {
    const month = dayjs(curr.judgeTime).format('YYYY-MM')
    acc[month] = (acc[month] || 0) + 1
    return acc
  }, {})

  // 获取排序后的月份
  const months = Object.keys(monthStats).sort()

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: '{b}: {c}件',
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        data: months.map((month) => monthStats[month]),
        type: 'line',
        smooth: true,
        symbolSize: 8,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.2,
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const LawSuitCard = ({ lawsuit }: { lawsuit: LawSuitItem }) => {
  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500}>
            {lawsuit.title}
          </Text>
        </div>

        <div className="flex items-center gap-2 flex-wrap">
          <Badge variant="light">{lawsuit.caseType}</Badge>
          <Badge variant="light">{lawsuit.caseReason}</Badge>
          <Badge variant="light">{lawsuit.docType}</Badge>
        </div>

        <Text size="sm">案号：{lawsuit.caseNo}</Text>
        <Text size="sm">审理法院：{lawsuit.court}</Text>
        <Text size="sm">判决日期：{lawsuit.judgeTime}</Text>

        <Box mt={4}>
          <div className="grid grid-cols-1 gap-2">
            {lawsuit.casePersons.map((person, index) => (
              <div
                key={index}
                className="flex items-center gap-2 p-2"
                style={{
                  backgroundColor: '#f8f9fa',
                  borderRadius: '4px',
                }}
              >
                <Badge size="sm" variant="light">
                  {person.role}
                </Badge>
                <Text size="sm">{person.sptname || person.name}</Text>
                {person.result && (
                  <Text size="sm" c="dimmed" ml="auto">
                    {person.result}
                  </Text>
                )}
              </div>
            ))}
          </div>
        </Box>
      </Stack>
    </Box>
  )
}

export default function TianyanchaLawSuit({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_lawSuit ?? '{}')
  const lawsuits: LawSuitItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <LabeledTitle title={'案件类型分布'} />
          <CaseTypePieChart lawsuits={lawsuits} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'案件趋势'} />
          <CaseTrendChart lawsuits={lawsuits} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'案件详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={lawsuits.length === 0}>
              {lawsuits.map((lawsuit) => (
                <Box
                  key={lawsuit.caseNo}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <LawSuitCard lawsuit={lawsuit} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
