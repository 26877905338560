import LabeledContainer from '../../../components/LabeledContainer'
import React, { useMemo } from 'react'
import ReportWrapper from '../ReportWrapper'
import { Badge, Box, Grid, Paper, Stack, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import dayjs from 'dayjs'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface DetailItem {
  title: string
  content: string
}

interface CertificateItem {
  certNo: string
  endDate: string
  certificateName: string
  id: string
  detail: DetailItem[]
  startDate: string
}

const CertificateCard = ({ certificate }: { certificate: CertificateItem }) => {
  const isValid = dayjs(certificate.endDate).isAfter(dayjs())
  const status = certificate.detail.find((item) => item.title === '证书状态')?.content

  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500} style={{ flex: 1 }}>
            {certificate.certificateName}
          </Text>
          <Badge variant="light" color={isValid ? 'green' : 'red'}>
            {status || (isValid ? '有效' : '已过期')}
          </Badge>
        </div>

        <div className="flex items-center gap-4">
          <Text size="sm" c="dimmed">
            证书编号：{certificate.certNo}
          </Text>
          <Text size="sm" c="dimmed">
            有效期：{dayjs(certificate.startDate).format('YYYY-MM-DD')} 至{' '}
            {dayjs(certificate.endDate).format('YYYY-MM-DD')}
          </Text>
        </div>
      </Stack>
    </Box>
  )
}

const CertificateSummary = ({ certificates }: { certificates: CertificateItem[] }) => {
  const summary = useMemo(() => {
    const now = dayjs()
    const validCerts = certificates.filter((cert) => dayjs(cert.endDate).isAfter(now))
    const expiredCerts = certificates.filter((cert) => !dayjs(cert.endDate).isAfter(now))
    const nearExpirationCerts = validCerts.filter((cert) => {
      const monthsLeft = dayjs(cert.endDate).diff(now, 'month')
      return monthsLeft <= 3
    })

    const nextExpiration =
      validCerts.length > 0
        ? dayjs(Math.min(...validCerts.map((cert) => new Date(cert.endDate).getTime()))).format('YYYY-MM-DD')
        : '暂无'

    return {
      total: certificates.length,
      valid: validCerts.length,
      expired: expiredCerts.length,
      nearExpiration: nearExpirationCerts.length,
      nextExpiration,
    }
  }, [certificates])

  return (
    <ItemWrapper>
      <LabeledTitle title="数据概览" />
      <Grid>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              总证书数量
            </Text>
            <Text fw={700} size="xl">
              {summary.total}
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              有效证书
            </Text>
            <Text fw={700} size="xl" c="green">
              {summary.valid}
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              即将过期
            </Text>
            <Text fw={700} size="xl" c="orange">
              {summary.nearExpiration}
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              下次过期时间
            </Text>
            <Text fw={700} size="xl" style={{ whiteSpace: 'nowrap' }}>
              {summary.nextExpiration}
            </Text>
          </Paper>
        </Grid.Col>
      </Grid>
    </ItemWrapper>
  )
}

const CertificateStatusChart = ({ certificates }: { certificates: CertificateItem[] }) => {
  const option = useMemo(() => {
    const now = dayjs()
    const statusCount = certificates.reduce(
      (acc, cert) => {
        const isValid = dayjs(cert.endDate).isAfter(now)
        const monthsLeft = dayjs(cert.endDate).diff(now, 'month')
        let status = isValid ? (monthsLeft <= 3 ? '即将过期' : '有效') : '已过期'
        acc[status] = (acc[status] || 0) + 1
        return acc
      },
      {} as Record<string, number>
    )

    const data = Object.entries(statusCount).map(([name, value]) => ({ name, value }))
    const colors = {
      有效: '#2ecc71',
      即将过期: '#f1c40f',
      已过期: '#e74c3c',
    }

    return {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: data.map((item) => ({
            ...item,
            itemStyle: {
              color: colors[item.name as keyof typeof colors],
            },
          })),
        },
      ],
    }
  }, [certificates])

  return (
    <ItemWrapper>
      <LabeledTitle title="证书状态分布" />
      <ReactECharts option={option} style={{ height: '300px' }} />
    </ItemWrapper>
  )
}

export default function TianyanchaCertificate({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_certificate ?? '{}')
  const certificates: CertificateItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <CertificateSummary certificates={certificates} />
        <CertificateStatusChart certificates={certificates} />
        <ItemWrapper>
          <LabeledTitle title="证书详情" />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={certificates.length === 0}>
              {certificates.map((certificate) => (
                <Box
                  key={certificate.id}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <CertificateCard certificate={certificate} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
