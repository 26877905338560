import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import CompanyNonBankingApplicationRecordsOverview from './CompanyNonBankingApplicationRecordsOverview'
import CompanyNonBankingInstitutionalApplicationRecords from './CompanyNonBankingInstitutionalApplicationRecords'

interface NonBankingApplicationRecordsProps {
  data: any
  title: string
}

export default function CompanyNonBankingApplicationRecords({ data, title }: NonBankingApplicationRecordsProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <CompanyNonBankingApplicationRecordsOverview data={data} />
      <CompanyNonBankingInstitutionalApplicationRecords data={data} />
    </ReportWrapper>
  )
}
