import LabeledContainer from '../../../components/LabeledContainer'
import React, { useMemo } from 'react'
import ReportWrapper from '../ReportWrapper'
import { Badge, Box, Grid, Group, Paper, Stack, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import dayjs from 'dayjs'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface BidItem {
  publishTime: string
  bidUrl: string
  purchaser: string
  link: string
  bidAmount: string
  title: string
  type: string
  uuid: string
  content: string
  proxy: string
  bidWinner: string
  abs: string
  province: string
  intro: string
  id: string
}

const formatMoney = (amount: string | number) => {
  if (!amount) return '0'
  const num = typeof amount === 'string' ? parseFloat(amount) : amount
  if (isNaN(num)) return '0'
  return num.toLocaleString('zh-CN', {
    style: 'currency',
    currency: 'CNY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

const BidCard = ({ bid }: { bid: BidItem }) => {
  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500} style={{ flex: 1 }}>
            {bid.title}
          </Text>
          <Badge variant="light" color="blue">
            {bid.type}
          </Badge>
        </div>

        <div className="flex items-center gap-4">
          <Text size="sm" c="dimmed">
            采购方：{bid.purchaser}
          </Text>
          {bid.province && (
            <Text size="sm" c="dimmed">
              地区：{bid.province}
            </Text>
          )}
          <Text size="sm" c="dimmed">
            发布时间：{dayjs(Number(bid.publishTime)).format('YYYY-MM-DD')}
          </Text>
        </div>
      </Stack>
    </Box>
  )
}

const BidSummary = ({ bids }: { bids: BidItem[] }) => {
  const summary = useMemo(() => {
    const provinces = new Set(bids.filter((bid) => bid.province).map((bid) => bid.province))
    const totalAmount = bids.reduce((sum, bid) => {
      if (!bid.bidAmount) return sum
      const amount = parseFloat(bid.bidAmount)
      return isNaN(amount) ? sum : sum + amount
    }, 0)
    const latestBid =
      bids.length > 0 ? dayjs(Math.max(...bids.map((bid) => Number(bid.publishTime)))).format('YYYY-MM-DD') : '暂无'

    return {
      total: bids.length,
      provinces: provinces.size,
      totalAmount,
      latestBid,
    }
  }, [bids])

  return (
    <ItemWrapper>
      <LabeledTitle title="数据概览" />
      <Grid>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              总招标数量
            </Text>
            <Text fw={700} size="xl">
              {summary.total}
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              涉及地区数
            </Text>
            <Text fw={700} size="xl">
              {summary.provinces}
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              总中标金额
            </Text>
            <Text fw={700} size="xl">
              {formatMoney(summary.totalAmount)}
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" c="dimmed">
              最近招标日期
            </Text>
            <Text fw={700} size="xl" style={{ whiteSpace: 'nowrap' }}>
              {summary.latestBid}
            </Text>
          </Paper>
        </Grid.Col>
      </Grid>
    </ItemWrapper>
  )
}

const BidTypeChart = ({ bids }: { bids: BidItem[] }) => {
  const option = useMemo(() => {
    const typeCount = bids.reduce(
      (acc, bid) => {
        acc[bid.type] = (acc[bid.type] || 0) + 1
        return acc
      },
      {} as Record<string, number>
    )

    const data = Object.entries(typeCount).map(([name, value]) => ({ name, value }))

    return {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data,
        },
      ],
    }
  }, [bids])

  return (
    <ItemWrapper>
      <LabeledTitle title="招标类型分布" />
      <ReactECharts option={option} style={{ height: '300px' }} />
    </ItemWrapper>
  )
}

const BidTrendChart = ({ bids }: { bids: BidItem[] }) => {
  const option = useMemo(() => {
    const timeData = bids.reduce(
      (acc, bid) => {
        const date = dayjs(Number(bid.publishTime)).format('YYYY-MM')
        acc[date] = (acc[date] || 0) + 1
        return acc
      },
      {} as Record<string, number>
    )

    const sortedDates = Object.keys(timeData).sort()

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: sortedDates,
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: sortedDates.map((date) => timeData[date]),
          type: 'line',
          smooth: true,
          areaStyle: {
            opacity: 0.3,
          },
        },
      ],
    }
  }, [bids])

  return (
    <ItemWrapper>
      <LabeledTitle title="招标趋势" />
      <ReactECharts option={option} style={{ height: '300px' }} />
    </ItemWrapper>
  )
}

export default function TianyanchaBids({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_bids ?? '{}')
  const bids: BidItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <BidSummary bids={bids} />
        <Group grow>
          <BidTypeChart bids={bids} />
          <BidTrendChart bids={bids} />
        </Group>
        <ItemWrapper>
          <LabeledTitle title={'招投标详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={bids.length === 0}>
              {bids.map((bid) => (
                <Box
                  key={bid.uuid}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <BidCard bid={bid} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
