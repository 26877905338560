import React, { useEffect, useState } from 'react'
import '../App.css'
import QueryPersonalReportForm from './components/QueryPersonalReportForm'
import { Center, Image, Loader, Stack } from '@mantine/core'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { facade } from '../api/orderService'
import { TemplateMerchantInterface } from '../typings/orderTypes'
import RightFloating from '../components/RightFloating'
import FloatingButton from '../components/FloatingButton'
import QueryCompanyReportForm from './components/QueryCompanyReportForm'
import ReportElements from './components/ReportElements'
import InterceptorWrapper from '../components/InterceptorWrapper'

export default function QueryReport() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const linkId = searchParams.get('linkId') || ''
  const [template, setTemplate] = useState<TemplateMerchantInterface>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (linkId === '') {
      navigate('/login')
      return
    }
    setLoading(true)
    facade(linkId)
      .then((res) => {
        setTemplate(res)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [linkId, navigate])

  if (loading) {
    return (
      <Center h="100vh" bg="#5087EB">
        <Loader color="white" size="lg" type="bars" />
      </Center>
    )
  }

  return (
    <InterceptorWrapper>
      <div className="max-w-xl m-auto">
        <div className="bg-[#5087EB] pb-4">
          <Image src="./query_bg.png" />
          <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
            {template?.templateType == 1 && <QueryPersonalReportForm template={template} />}
            {template?.templateType == 2 && <QueryCompanyReportForm template={template} />}
          </div>
          <div className="mx-4 bg-white rounded-3xl px-6 py-5">
            <ReportElements modules={template?.modules} />
          </div>
          <RightFloating>
            <Stack align={'flex-end'}>
              <FloatingButton
                image={'./history.png'}
                onClick={() => {
                  navigate('/orders')
                }}
              />
            </Stack>
          </RightFloating>
        </div>
      </div>
    </InterceptorWrapper>
  )
}
