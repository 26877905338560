import http, { ResponseData } from './httpService'
import {
  BindMerchantWxUserReqVO,
  IsaacMerchantRechargeReqVO,
  IsaacMerchantSignupReqVO,
  MerchantInfoVO,
} from '../typings/merchant'

export const signupMerchant = async (vo: IsaacMerchantSignupReqVO): Promise<void> => {
  await http.post<ResponseData<String>>('/fe/user/center/merchant/signup', vo)
}

export const recharge = async (param: IsaacMerchantRechargeReqVO): Promise<string> => {
  const { data } = await http.post<ResponseData<string>>('/fe/user/center/merchant/recharge', param)
  return data.data
}

export const bindWxUser = async (param: BindMerchantWxUserReqVO): Promise<string> => {
  const { data } = await http.post<ResponseData<string>>('/fe/user/center/merchant/bindWxUser', param)
  return data.data
}

export const getMerchantInfo = async (merchantId: string): Promise<MerchantInfoVO> => {
  const { data } = await http.get<ResponseData<MerchantInfoVO>>('/fe/user/center/merchant/info/' + merchantId)
  return data.data
}
