import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import CompanyBankApplicationRecordsOverview from './CompanyBankApplicationRecordsOverview'
import CompanyBankInstitutionalApplicationRecords from './CompanyBankInstitutionalApplicationRecords'

interface CompanyBankApplicationRecordsProps {
  data: any
  title: string
}

export default function CompanyBankApplicationRecords({ data, title }: CompanyBankApplicationRecordsProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <CompanyBankApplicationRecordsOverview data={data} />
      <CompanyBankInstitutionalApplicationRecords data={data} />
    </ReportWrapper>
  )
}
