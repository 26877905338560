import React from 'react'
import { Badge, Box, Divider, Group, Stack, Text } from '@mantine/core'

interface BasicInfo {
  creditCode: string
  legalPersonName: string
  regCapital: string
  estiblishTime: string
  regorg: string
  opscope: string
  regStatus: string
}

interface StockHolderInfo {
  orgHolderType: string
  investDate: string
  subscriptAmt: string
  investRate: string
}

interface AdminPenaltyInfo {
  decisionDate: string
  departmentName: string
  reason: string
  content: string
}

interface ExecutedPersonInfo {
  caseCode: string
  pname: string
  caseCreateTime: string
  execCourtName: string
  execMoney: string
}

interface DishonestExecutedPersonInfo {
  casecode: string
  courtname: string
  regdate: string
  unperformPart: string
  performedPart: string
  iname: string
  disrupttypename: string
  performance: string
}

interface RelationCompanyItem {
  orgName: string
  basicInfo: BasicInfo
  relationship: string[]
  stockHolderItem?: StockHolderInfo
  adminPenalty?: AdminPenaltyInfo
  executedPerson?: ExecutedPersonInfo
  dishonestExecutedPerson?: DishonestExecutedPersonInfo
}

interface RelationCompanyProps {
  number: number
  item: RelationCompanyItem
}

const RELATIONSHIP_MAP = {
  sh: '股东',
  lp: '法人',
  tm: '高管',
  his_tm: '历史高管',
  his_sh: '历史股东',
  his_lp: '历史法人',
}

const getRelationship = (str: string): string => {
  for (const [key, value] of Object.entries(RELATIONSHIP_MAP)) {
    if (str === key) return value
  }
  return str
}

const BasicInfo: React.FC<{ info: BasicInfo }> = ({ info }) => (
  <>
    <Text size="sm" c="dark">
      统一社会信用代码：{info.creditCode}
    </Text>
    <Text size="sm" c="dark">
      法定代表人姓名：{info.legalPersonName}
    </Text>
    <Text size="sm" c="dark">
      注册资本：{info.regCapital}
    </Text>
    <Text size="sm" c="dark">
      注册时间：{info.estiblishTime}
    </Text>
    <Text size="sm" c="dark">
      登记机关：{info.regorg}
    </Text>
    <Text size="sm" c="dark">
      经营范围：{info.opscope}
    </Text>
  </>
)

const StockHolderItem: React.FC<{ info: StockHolderInfo }> = ({ info }) => (
  <>
    <Divider variant="dashed" />
    <Text size="sm" c="dark" fw={500}>
      查询人持股信息
    </Text>
    <Text size="sm" c="dark">
      股东类型：{info.orgHolderType}
    </Text>
    <Text size="sm" c="dark">
      出资时间：{info.investDate}
    </Text>
    <Text size="sm" c="dark">
      出资金额：{info.subscriptAmt}
    </Text>
    <Text size="sm" c="dark">
      出资比例：{info.investRate}
    </Text>
  </>
)

const AdminPenalty: React.FC<{ info: AdminPenaltyInfo }> = ({ info }) => (
  <>
    <Divider variant="dashed" />
    <Text size="sm" c="dark" fw={500}>
      行政处罚
    </Text>
    <Text size="sm" c="dark">
      处罚时间：{info.decisionDate}
    </Text>
    <Text size="sm" c="dark">
      处罚单位：{info.departmentName}
    </Text>
    <Text size="sm" c="dark">
      处罚事由：{info.reason}
    </Text>
    <Text size="sm" c="dark">
      处罚结果：{info.content}
    </Text>
  </>
)

const ExecutedPerson: React.FC<{ info: ExecutedPersonInfo }> = ({ info }) => (
  <>
    <Divider variant="dashed" />
    <Text size="sm" c="dark" fw={500}>
      被执行人（人员）
    </Text>
    <Text size="sm" c="dark">
      案号：{info.caseCode}
    </Text>
    <Text size="sm" c="dark">
      被执行人名称：{info.pname}
    </Text>
    <Text size="sm" c="dark">
      立案日期：{info.caseCreateTime}
    </Text>
    <Text size="sm" c="dark">
      执行法院：{info.execCourtName}
    </Text>
    <Text size="sm" c="dark">
      执行标的（元）：{info.execMoney}
    </Text>
  </>
)

const DishonestExecutedPerson: React.FC<{ info: DishonestExecutedPersonInfo }> = ({ info }) => (
  <>
    <Divider variant="dashed" />
    <Text size="sm" c="dark" fw={500}>
      失信被执行人（人员）
    </Text>
    <Text size="sm" c="dark">
      案号：{info.casecode}
    </Text>
    <Text size="sm" c="dark">
      执行法院：{info.courtname}
    </Text>
    <Text size="sm" c="dark">
      立案日期：{info.regdate}
    </Text>
    <Text size="sm" c="dark">
      未履行部分：{info.unperformPart}
    </Text>
    <Text size="sm" c="dark">
      履行部分：{info.performedPart}
    </Text>
    <Text size="sm" c="dark">
      被执行人姓名：{info.iname}
    </Text>
    <Text size="sm" c="dark">
      失信行为：{info.disrupttypename}
    </Text>
    <Text size="sm" c="dark">
      履行情况：{info.performance}
    </Text>
  </>
)

const RelationCompany: React.FC<RelationCompanyProps> = ({ item }) => {
  return (
    <Box
      bg="var(--mantine-color-blue-light)"
      p="xs"
      style={{
        borderRadius: 'var(--mantine-radius-sm)',
      }}
    >
      <Stack gap="xs" className="text-gray-500">
        <Text size="sm" c="dark" fw={500}>
          {item.orgName}
          <Badge ml={4} style={{ backgroundColor: 'blue' }}>
            {item.basicInfo.regStatus}
          </Badge>
        </Text>
        <Group gap={6}>
          {item.relationship.map((relationship, index) => (
            <Badge key={index} variant="light" style={{ color: 'blue' }}>
              {getRelationship(relationship)}
            </Badge>
          ))}
        </Group>
        <BasicInfo info={item.basicInfo} />
        {item.stockHolderItem && <StockHolderItem info={item.stockHolderItem} />}
        {item.adminPenalty && <AdminPenalty info={item.adminPenalty} />}
        {item.executedPerson && <ExecutedPerson info={item.executedPerson} />}
        {item.dishonestExecutedPerson && <DishonestExecutedPerson info={item.dishonestExecutedPerson} />}
      </Stack>
    </Box>
  )
}

export default RelationCompany
