import { useEffect, useState } from 'react'

interface WechatAuthProps {
  appId: string
}

const useWechatAuth = ({ appId }: WechatAuthProps) => {
  const [wxCode, setWxCode] = useState<string>('')
  const [wxAuthLoading, setWxAuthLoading] = useState(false)

  useEffect(() => {
    if (!appId) {
      setWxCode('')
      return
    }

    const doAuth = async () => {
      setWxAuthLoading(true)
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code')

        if (code) {
          setWxCode(code)
        }
        const redirectUri = encodeURIComponent(window.location.href)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      } finally {
        setWxAuthLoading(false)
      }
    }

    doAuth().then()
  }, [appId])

  return { wxCode, wxAuthLoading }
}
export default useWechatAuth
