import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Grid, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'
import ItemWrapper from '../../../components/ItemWrapper'

interface InfoItemProps {
  label: string
  value: string | number | null
  span?: number
}

const InfoItem = ({ label, value, span = 6 }: InfoItemProps) => {
  if (!value) return null
  return (
    <Grid.Col span={span}>
      <Stack gap={4}>
        <Text size="sm" c="dimmed">
          {label}
        </Text>
        <Text size="sm" style={{ wordBreak: 'break-all' }}>
          {value}
        </Text>
      </Stack>
    </Grid.Col>
  )
}

export default function TianyanchaBasicInfo({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_basic_info ?? '{}')

  const formatDate = (timestamp: number) => {
    if (!timestamp) return ''
    return dayjs(timestamp).format('YYYY-MM-DD')
  }

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ItemWrapper>
        <Grid gutter="lg">
          <InfoItem label="企业名称" value={jsonData.name} span={12} />
          <InfoItem label="统一社会信用代码" value={jsonData.creditCode} />
          <InfoItem label="注册资本" value={jsonData.regCapital} />
          <InfoItem label="成立日期" value={formatDate(jsonData.estiblishTime)} />
          <InfoItem label="经营状态" value={jsonData.regStatus} />
          <InfoItem label="法定代表人" value={jsonData.legalPersonName} />
          <InfoItem label="所属地区" value={jsonData.city} />
          <InfoItem label="企业类型" value={jsonData.companyOrgType} span={12} />
          <InfoItem label="注册地址" value={jsonData.regLocation} span={12} />
          <InfoItem label="经营范围" value={jsonData.businessScope} span={12} />
        </Grid>
      </ItemWrapper>
    </ReportWrapper>
  )
}
