import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Box, Stack, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import dayjs from 'dayjs'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface AbnormalItem {
  putReason: string
  putDepartment: string
  putDate: string
  removeDate: string
  removeDepartment: string
  removeReason: string
}

const AbnormalStatusPieChart = ({ abnormals }: { abnormals: AbnormalItem[] }) => {
  // 统计已移出和未移出的数量
  const stats = abnormals.reduce(
    (acc: { [key: string]: number }, curr) => {
      const status = curr.removeDate ? '已移出' : '未移出'
      acc[status] = (acc[status] || 0) + 1
      return acc
    },
    { 已移出: 0, 未移出: 0 }
  )

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}条',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'outside',
          formatter: '{b}\n{c}条',
          fontSize: 12,
        },
        labelLine: {
          show: true,
          length: 10,
          length2: 10,
        },
        data: Object.entries(stats).map(([name, value]) => ({
          name,
          value,
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const AbnormalTrendChart = ({ abnormals }: { abnormals: AbnormalItem[] }) => {
  // 按月份统计列入和移出的数量
  const monthStats = abnormals.reduce((acc: { [key: string]: { put: number; remove: number } }, curr) => {
    const putMonth = dayjs(curr.putDate).format('YYYY-MM')
    const removeMonth = curr.removeDate ? dayjs(curr.removeDate).format('YYYY-MM') : null

    if (!acc[putMonth]) {
      acc[putMonth] = { put: 0, remove: 0 }
    }
    acc[putMonth].put++

    if (removeMonth) {
      if (!acc[removeMonth]) {
        acc[removeMonth] = { put: 0, remove: 0 }
      }
      acc[removeMonth].remove++
    }

    return acc
  }, {})

  // 获取排序后的月份
  const months = Object.keys(monthStats).sort()

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any[]) {
        const month = params[0].axisValue
        const putCount = monthStats[month].put
        const removeCount = monthStats[month].remove
        return `${month}<br/>列入：${putCount}条<br/>移出：${removeCount}条`
      },
    },
    legend: {
      data: ['列入', '移出'],
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        name: '列入',
        data: months.map((month) => monthStats[month].put),
        type: 'line',
        smooth: true,
        symbolSize: 8,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.2,
        },
      },
      {
        name: '移出',
        data: months.map((month) => monthStats[month].remove),
        type: 'line',
        smooth: true,
        symbolSize: 8,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.2,
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const AbnormalCard = ({ abnormal }: { abnormal: AbnormalItem }) => {
  const isRemoved = abnormal.removeDate && abnormal.removeReason

  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500}>
            {abnormal.putReason}
          </Text>
        </div>

        <Text size="sm">列入日期：{abnormal.putDate}</Text>
        <Text size="sm">列入机关：{abnormal.putDepartment}</Text>

        {isRemoved && (
          <>
            <Text size="sm">移出日期：{abnormal.removeDate}</Text>
            <Text size="sm">移出机关：{abnormal.removeDepartment}</Text>
            <Text size="sm">移出原因：{abnormal.removeReason}</Text>
          </>
        )}
      </Stack>
    </Box>
  )
}

export default function TianyanchaAbnormal({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_abnormal ?? '{}')
  const abnormals: AbnormalItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <LabeledTitle title={'移出状态分布'} />
          <AbnormalStatusPieChart abnormals={abnormals} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'异常记录趋势'} />
          <AbnormalTrendChart abnormals={abnormals} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'异常详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={abnormals.length === 0}>
              {abnormals.map((abnormal, index) => (
                <Box
                  key={index}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <AbnormalCard abnormal={abnormal} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
