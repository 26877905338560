import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Box, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface ZhixingItem {
  caseCode: string
  partyCardNum: string
  pname: string
  execCourtName: string
  caseCreateTime: number
  execMoney: string
}

const formatMoney = (money: string) => {
  const num = parseFloat(money)
  if (num >= 10000) {
    return `${(num / 10000).toFixed(2)}万元`
  }
  return `${num}元`
}

const MoneyRangeChart = ({ zhixings }: { zhixings: ZhixingItem[] }) => {
  // 统计不同金额范围的案件数量
  const ranges = [
    { name: '1万以下', min: 0, max: 10000 },
    { name: '1-10万', min: 10000, max: 100000 },
    { name: '10-100万', min: 100000, max: 1000000 },
    { name: '100万以上', min: 1000000, max: Infinity },
  ]

  const stats = ranges.reduce((acc: { [key: string]: number }, range) => {
    acc[range.name] = 0
    return acc
  }, {})

  zhixings.forEach((item) => {
    const money = parseFloat(item.execMoney)
    const range = ranges.find((r) => money >= r.min && money < r.max)
    if (range) {
      stats[range.name]++
    }
  })

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}件',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'outside',
          formatter: '{b}\n{c}件',
          fontSize: 12,
        },
        labelLine: {
          show: true,
          length: 10,
          length2: 10,
        },
        data: Object.entries(stats).map(([name, value]) => ({
          name,
          value,
        })),
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const ZhixingTrendChart = ({ zhixings }: { zhixings: ZhixingItem[] }) => {
  // 按月份统计被执行案件数量
  const monthStats = zhixings.reduce((acc: { [key: string]: number }, curr) => {
    const month = dayjs(curr.caseCreateTime).format('YYYY-MM')
    acc[month] = (acc[month] || 0) + 1
    return acc
  }, {})

  // 获取排序后的月份
  const months = Object.keys(monthStats).sort()

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: '{b}: {c}件',
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        data: months.map((month) => monthStats[month]),
        type: 'line',
        smooth: true,
        symbolSize: 8,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.2,
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const ZhixingCard = ({ zhixing }: { zhixing: ZhixingItem }) => {
  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500}>
            被执行金额：{formatMoney(zhixing.execMoney)}
          </Text>
        </div>

        <Text size="sm">案号：{zhixing.caseCode}</Text>
        <Text size="sm">执行法院：{zhixing.execCourtName}</Text>
        <Text size="sm">立案时间：{dayjs(zhixing.caseCreateTime).format('YYYY-MM-DD')}</Text>
        <Text size="sm">身份证号/组织机构代码：{zhixing.partyCardNum}</Text>
      </Stack>
    </Box>
  )
}

export default function TianyanchaZhixinginfo({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_zhixinginfo ?? '{}')
  const zhixings: ZhixingItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <LabeledTitle title={'执行金额分布'} />
          <MoneyRangeChart zhixings={zhixings} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'执行趋势'} />
          <ZhixingTrendChart zhixings={zhixings} />
        </ItemWrapper>
        <ItemWrapper>
          <LabeledTitle title={'执行详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={zhixings.length === 0}>
              {zhixings.map((zhixing) => (
                <Box
                  key={zhixing.caseCode}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <ZhixingCard zhixing={zhixing} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
