import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import { Box, Grid, Stack, Text } from '@mantine/core'
import ItemWrapper from '../../../components/ItemWrapper'
import ReactECharts from 'echarts-for-react'
import dayjs from 'dayjs'
import LabeledTitle from '../../../components/LabeledTitle'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface PunishmentItem {
  departmentName: string
  reason: string
  content: string
  decisionDate: string
  punishNumber: string
  source: string
}

const DepartmentBarChart = ({ punishments }: { punishments: PunishmentItem[] }) => {
  // 统计各部门的处罚次数
  const departmentStats = punishments.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.departmentName] = (acc[curr.departmentName] || 0) + 1
    return acc
  }, {})

  const departments = Object.keys(departmentStats)
  const counts = departments.map((dept) => departmentStats[dept])

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: departments,
    },
    series: [
      {
        name: '处罚次数',
        type: 'bar',
        data: counts,
        itemStyle: {
          color: '#ff6b6b',
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const PunishmentTimeline = ({ punishments }: { punishments: PunishmentItem[] }) => {
  // 按时间排序并统计
  const timeData = punishments
    .map((p) => ({
      date: dayjs(p.decisionDate).format('YYYY-MM'),
      department: p.departmentName,
    }))
    .sort((a, b) => a.date.localeCompare(b.date))

  const dates = Array.from(new Set(timeData.map((d) => d.date)))
  const counts = dates.map((date) => timeData.filter((d) => d.date === date).length)

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: dates,
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: '处罚数量',
        type: 'line',
        data: counts,
        itemStyle: {
          color: '#ff6b6b',
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(255, 107, 107, 0.3)',
              },
              {
                offset: 1,
                color: 'rgba(255, 107, 107, 0)',
              },
            ],
          },
        },
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '300px' }} />
}

const PunishmentSummary = ({ punishments }: { punishments: PunishmentItem[] }) => {
  const totalCount = punishments.length
  const departmentCount = new Set(punishments.map((p) => p.departmentName)).size
  const latestPunishment =
    punishments.length > 0
      ? punishments.reduce((latest, curr) =>
          dayjs(curr.decisionDate).isAfter(dayjs(latest.decisionDate)) ? curr : latest
        )
      : null

  return (
    <Grid grow>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="red">
            {totalCount}
          </Text>
          <Text size="sm" c="dimmed">
            总处罚次数
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700} c="red">
            {departmentCount}
          </Text>
          <Text size="sm" c="dimmed">
            处罚部门数
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={4}>
        <Box className="p-4 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <Text size="xl" fw={700}>
            {latestPunishment ? dayjs(latestPunishment.decisionDate).format('YYYY-MM') : '-'}
          </Text>
          <Text size="sm" c="dimmed">
            最近处罚时间
          </Text>
        </Box>
      </Grid.Col>
    </Grid>
  )
}

const PunishmentCard = ({ punishment }: { punishment: PunishmentItem }) => {
  return (
    <Box>
      <Stack gap="xs">
        <div className="flex items-center gap-2">
          <Text size="sm" fw={500}>
            {punishment.departmentName}
          </Text>
        </div>

        <Text size="sm">处罚文号：{punishment.punishNumber}</Text>
        <Text size="sm">处罚日期：{punishment.decisionDate}</Text>
        <Text size="sm">来源：{punishment.source}</Text>
        <Text size="sm">处罚原因：{punishment.reason}</Text>
        {punishment.content && <Text size="sm">处罚内容：{punishment.content}</Text>}
      </Stack>
    </Box>
  )
}

export default function TianyanchaPunishmentInfo({ data, title }: { data: any; title: string }) {
  const jsonData = JSON.parse(data.tianyancha_punishmentInfo ?? '{}')
  const punishments: PunishmentItem[] = jsonData.items || []

  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <Stack>
        <ItemWrapper>
          <PunishmentSummary punishments={punishments} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'处罚部门分布'} />
          <DepartmentBarChart punishments={punishments} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'处罚时间趋势'} />
          <PunishmentTimeline punishments={punishments} />
        </ItemWrapper>

        <ItemWrapper>
          <LabeledTitle title={'处罚详情'} />
          <Stack gap="md">
            <ExpandableListWrapper isEmpty={punishments.length === 0}>
              {punishments.map((punishment, index) => (
                <Box
                  key={index}
                  p="md"
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <PunishmentCard punishment={punishment} />
                </Box>
              ))}
            </ExpandableListWrapper>
          </Stack>
        </ItemWrapper>
      </Stack>
    </ReportWrapper>
  )
}
