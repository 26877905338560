import { Badge } from '@mantine/core'
import React from 'react'

interface ReportElementProps {
  name: string
}

export default function ReportElement({ name }: ReportElementProps) {
  return (
    <Badge
      size="lg"
      radius="md"
      variant="light"
      className="px-4 py-2 transition-all hover:scale-105"
      styles={{
        root: {
          backgroundColor: 'rgb(239 246 255)',
          color: 'rgb(59 130 246)',
          fontSize: '0.9rem',
          cursor: 'default',
        },
      }}
    >
      {name}
    </Badge>
  )
}
