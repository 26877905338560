import { Group, Stack } from '@mantine/core'
import React from 'react'
import ReportElement from './ReportElement'

interface ReportElementsProps {
  modules?: string[]
}

export default function ReportElements({ modules }: ReportElementsProps) {
  return (
    <Stack>
      <div className={'text-left text-lg font-bold'}>报告主要内容</div>
      <Group gap="sm" className="flex-wrap">
        {modules?.map((module, index) => <ReportElement key={index} name={module} />)}
      </Group>
    </Stack>
  )
}
